
.lei-wrapper{
    padding: 20px;
}
.lei-field-wrapper{
    display: flex;
    gap: 20px;
    margin: 20px 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}
.lei-field-wrapper.last{
    border: none;
}
.lei-field-wrapper .MuiFormControl-root.MuiTextField-root{
    min-width: 200px;
}
.lei-wrapper h3{
    font-size: 20px;
}
