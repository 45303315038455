.App {
  text-align: center;
  
  /*background-image: url('asset/img/gradBg2.PNG');*/
  background-repeat: no-repeat;
  background-size: cover;
  background: #ebebeb;
}
.App.dash{  
  padding-top: 75px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html{
  overflow: overlay;
}

.MuiMenu-paper{
  background-image: url('asset/img/gradBg2.PNG');
  color: rgb(103, 36, 240);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiCardHeader-title{
  /* font-size: 15px !important; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bul-title-heading {
  padding: 25px;
}
.bul-title-heading h2 {
  font-size: 28px;
  font-weight: 600;
}
.profile-pic {
  background-image: url('asset/img/avatar1.png');
  background-size: 164px;
  background-repeat: no-repeat;
  width: 164px;
  height: 164px;
  margin: 30px auto;
}
.MuiFormControl-root.MuiTextField-root {
  max-width: 200px;
}
.bul-login .MuiFormControl-root.MuiTextField-root {
  max-width: none;
}