
.vendor-wrapper{
    padding: 20px;
}
.vendor-field-wrapper{
    display: flex;
    gap: 20px;
    margin: 20px 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    flex-wrap: wrap;
}
.vendor-field-wrapper.last{
    border: none;
}
.vendor-field-wrapper .MuiFormControl-root.MuiTextField-root{
    min-width: 200px;
}
.vendor-wrapper h3{
    font-size: 20px;
}
.textarea-wrapper{
    margin: -15px 0;
    margin-bottom: 0px;
}
.textarea-wrapper h4{
    position: relative;
    top: 8px;
    background-color: white;
    width: fit-content;
    left: 10px;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 12px;
}
.textarea-wrapper textarea{
    height: 55px;
    padding: 10px;
    border-color: #bbb;
    border-radius: 4px;
    width: 200px;
}
.port-call-wrapper:not(:last-child) {
    margin-bottom: 50px;
    border-bottom: 10px dashed #1976d2;
    padding-bottom: 50px;
}
.service-wrapper:not(:last-child) {
    margin-bottom: 50px;
    border-bottom: 10px dashed #1976d2;
    padding-bottom: 50px;
}
.berthTable{
    border: 1px solid rgb(0, 0, 0); border-collapse: collapse;margin-top: 20px;
}
.berthTable tr{
    border-bottom: 1pt dotted;
}
.berthTable td{
    font-weight: bold; font-style: italic; padding: 6px;
}
