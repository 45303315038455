.form-builder .MuiButtonBase-root.MuiButton-contained{
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
}

.form-builder .MuiFormLabel-asterisk{
    color: red;
}

.textAreaLabel{
    text-align: left;
    position: relative;
    top: 7px;
    left: 10px;
    background-color: white;
    display: table-caption;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 12px;
    padding: 0 5px;
    white-space: nowrap;
}
.textAreaWrapper{
    margin-top: -10px;
}
.textAreaWrapper.solo{
    width: 100%;
}
.textAreaWrapper.solo textarea{
    width: 100%;
}
.textAreaWrapper textarea{
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 10px;
}