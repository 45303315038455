
.header-wrapper{
    text-align: left;
    padding: 5px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    background-color: white;
}
.logo{
    width: 140px;
    cursor: pointer;
}

.header-bar{
    display: flex;
}
.menus-wrapper{
    display: flex;    
    background: rgb(103,36,240);
    background: linear-gradient(90deg, rgba(103,36,240,1) 0%, rgba(169,36,240,1) 100%);
    margin: 5px -10px -5px -10px;
    padding-left: 140px;
}