:root {
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #0f1531;
  --blue: #5E50F9;
  --indigo: #6610f2;
  --purple: #6a008a;
  --pink: #E91E63;
  --red: #f96868;
  --orange: #f2a654;
  --yellow: #f6e84e;
  --green: #46c35f;
  --teal: #58d8a3;
  --cyan: #57c7d4;
  --gray: #434a54;
  --gray-light: #aab2bd;
  --gray-lighter: #e8eff4;
  --gray-lightest: #e6e9ed;
  --black: #000000;
  --primary: #f2125e;
  --secondary: #392ccd;
  --success: #17c964;
  --info: #2b80ff;
  --warning: #ff8300;
  --danger: #ff0000;
  --light: #e6e6e6;
  --txtblock: #232323;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
/* strong, */
sub,
sup,
tt,
var,
/* b,
u,
i, */
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/** **/

.andika-regular {
  font-family: "Andika", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.andika-bold {
  font-family: "Andika", sans-serif;
  font-weight: 700;
  font-style: normal;
}



body {
  font-family: "Andika", sans-serif;
  color: #333;
  font-size: 14px;
  background: #f3f3f3;
}

* {
  box-sizing: border-box;
}

.bul-login {
  background-image: url("./asset/img/login_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  position: relative;
}

.bul-lg-sec {
  width: 987px;
  margin: auto;
  background: #f7f7f7;
  border-radius: 25px;
  height: 600px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bul-lg-cnt {
  display: flex;
}

.bul-lg-forms {
  padding: 35px;
  width: 50%;
}

.bul-lg-ship-icon img {
  height: 100%;
  max-height: 600px;
}

.login-title {
  font-size: 28px;
  margin: 35px 0;
  font-weight: 700;
}

.bul-lg-form-cnt label {
  display: block;
}

.bul-lg-form-cnt input {
  border: 1px solid #9D9D9D;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  margin: 5px 0 25px;
}

.lb-right {
  text-align: right;
}

.bul-lg-forgot {
  color: #054ad9;
  text-decoration: none;
}

.bul-lg-forgot:hover {
  text-decoration: underline;
}

.bul-lg-btn-submit {
  background: rgb(103, 36, 240);
  background: linear-gradient(90deg, rgba(103, 36, 240, 1) 0%, rgba(169, 36, 240, 1) 100%);
  width: 100%;
  border: none;
  padding: 10px;
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  margin: 35px 0;
  border-radius: 6px;
  cursor: pointer;
}

.bul-lg-btn-submit:hover {
  background: linear-gradient(90deg, rgba(169, 36, 240, 1) 0%, rgba(103, 36, 240, 1) 100%);
}

.remember-me label {
  display: inline-block;
  font-size: 16px;
}

.remember-me input {
  width: 15px;
  margin: 0;
}

/** Main **/
.container-main {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.bul-nav-sidebar {
  min-height: calc(100vh - 64px);
  background: var(--white);
  padding: 25px 15px;
  width: 230px;
  z-index: 11;
  position: relative;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  box-shadow: 6px 16px 31px -18px #b7bcd1;
  -webkit-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -moz-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -ms-box-shadow: 6px 16px 31px -18px #b7bcd1;
}

.bul-logo-sec img {
  max-width: 175px;
}

.bu-nav-menu {
  margin: 30px 0 0;
}

.bu-nav-menu li a {
  text-decoration: none;
  font-size: 16px;
  color: var(--txtblock);
  margin: 25px 0 0;
  display: block;
}

.bu-nav-menu li a img {
  max-width: 20px;
}

.bu-nav-menu li a span {
  vertical-align: top;
  line-height: 20px;
  padding: 0 10px 0 20px;
}

.bul-header {
  width: 100%;
  height: 200px;
  background: rgb(103, 36, 240);
  background: linear-gradient(90deg, rgba(169, 36, 240, 1) 0%, rgba(103, 36, 240, 1) 100%);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 2px 3px 3px #d5d5d5;
}

.bul-header-sec {
  padding: 20px;
  display: flex;
}

.bul-head-userprofile {
  align-self: stretch;
  margin-left: auto;
}

.bul-user-icon img {
  max-width: 50px;
  border-radius: 50px;
}

.bul-top-user-li span {
  vertical-align: top;
  line-height: 50px;
  margin-left: 10px;
  color: #000;
}

.bul-container {
  margin: 0 auto;
  /* background-color: #fff; */
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  top: 25px;

}

.title-header {
  display: flex;
}

.title-header h2 {
  font-size: 22px;
  margin: 0 10px 0 70px;
  color: #fff;
  line-height: 20px;
  font-weight: 600;
}

.container-fluid {
  width: 100%;
  padding-left: 0;
}

.container-fluid main {
  height: calc(100% - 200px);
}

.bul-form-group {
  background: #f3f3f3;
  position: relative;
  top: 10px;
  width: 98%;
  margin: 0 auto 20px;
  border: 1px solid #ddd;
}

.bul-form-group h2 {
  padding: 1px 10px 3px;
  font-size: 1.3rem;
  border-top-left-radius: 0px;
  /* background: linear-gradient(90deg, rgb(141 92 242) 0%, rgb(221 179 244) 100%); */
  color: #fff;
  vertical-align: top;
  line-height: 30px;
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #7524f0;
}

.bul-form-group h2:after {
  position: absolute;
  content: '';
  background: #7524f0;
  width: 200px;
  height: 100%;
  left: 0;
  z-index: -1;
  border-top-right-radius: 90px;
}

.bul-form-list {
  padding: 10px 10px 20px;
  display: flex;
}

.bul-form-list label {
  display: block;
  margin: 8px 0;
}

.bul-form-list label small {
  color: #ff0057;
  font-size: 16px;
  font-weight: bold;
}

.col-md-3 {
  width: 25%;
}

.bul-form-list .bul-input {
  border: 1px solid #9D9D9D;
  border-radius: 6px;
  width: 90%;
  padding: 10px;
}

.bul-form-list .bul-in-disabled {
  background: #dbdada;
  cursor: not-allowed;
}

.bul-button-sec {
  margin: 15px;
  display: flex;
}

.btn {
  border: none;
  cursor: pointer;
  min-width: 100px;
  padding: 10px;
  font-size: 0.895rem;
  border-radius: 6px;
  color: var(--white);
  background: rgb(103, 36, 240);
  background: linear-gradient(90deg, rgba(103, 36, 240, 1) 0%, rgba(169, 36, 240, 1) 100%);
}

.btn:hover {
  background: linear-gradient(90deg, rgba(169, 36, 240, 1) 0%, rgba(103, 36, 240, 1) 100%);
}

.back-btn {
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);

}

.add-btn {
  background: linear-gradient(90deg, #049e85 0%, #099982 100%);
  margin-left: auto;
}

.menu-icon img {
  max-width: 30px;
  margin-right: 25px;
}

.bul-head-search {
  display: flex;
}

a:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;

}

#sidebar {
  position: relative;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active>.sidebar-link {
  color: #333;
}

.sidebar-link,
a.sidebar-link {
  cursor: pointer;
  position: relative;
  transition: background .1s ease-in-out;
}

.hamburger:before {
  width: 12px;
  border-radius: 5px;
}

.hamburger:after {
  width: 23px;
  border-radius: 5px;
}

[data-simplebar] {
  z-index: 9999;
  box-shadow: 0px 3px 2px #a7a5a5;
}

.sub-title-header {
  font-weight: 600;
  font-size: 22px;
  color: #2C2C3F;
}

.sidebar-toggle {
  width: 18px;
  border-radius: 5px;
}

.flag_ava img {
  width: 30px;
  height: 30px;
}

.dropdown-toggle:after {
  margin: 0 10px;
}

.avatar-info {
  padding: 18px 25px
}

.avatar-info h3 {
  font-size: 24px;
  color: #2C2C3F;
  font-weight: 600;
  font-family: Lato;
}

.avatar-info span {
  color: #66728D;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.avatar-info .dropdown-item {
  padding-left: 0px;
}

.avatar-info .dropdown-item span {
  color: #66728D;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 1px;
}

.navbar-vertical .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  color: var(--dashui-gray-500);
  content: "\f282";
  display: block;
  font-family: bootstrap-icons;
  font-size: 12px;
  margin-left: auto;
  position: absolute;
  right: 1.5rem;
  transition: .5s ease;
}

.navbar-vertical .navbar-nav .nav-icon {
  font-size: .875rem;
  opacity: .6;
  transition: all .5s;
}

.sidebar-item span {
  font-weight: 600;
}

.sidebar-item ul .sidebar-link .align-middle {
  font-weight: normal;
  color: #fff
}

.sidebar-item ul .sidebar-link .align-middle:hover {
  background: transparent;
  color: #fff
}

.sidebar-link.collapsed.sub-menu-li:before {
  position: absolute;
  content: '';
  background-image: url('./asset/img/down.svg');
  width: 25px;
  height: 25px;
  top: 0px;
  left: 85%;
  background-repeat: no-repeat;
  transform: rotate(-180deg);
}

.sidebar-link.sub-menu-li:before {
  position: absolute;
  content: '';
  background-image: url('./asset/img/down.svg');
  top: 0px;
  left: 85%;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
}

.sidebar-item {
  position: relative;
}

.sidebar-item .sidebar-item {
  display: flex;
  position: relative;
}

.img-trans-bg-left {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.bu-nav-menu li li a {
  margin: 10px 0px 0 15px;
  font-size: 14px;
  position: relative;
}

.bu-nav-menu li li a:after {
  position: absolute;
  content: '';
  background: #fff;
  width: 7px;
  height: 7px;
  top: 7px;
  left: 0;
  border-radius: 15px;

}

.hamburger {
  position: relative;
}

.hamburger,
.hamburger:after,
.hamburger:before {
  background: #495057;
  border-radius: 1px;
  content: "";
  cursor: pointer;
  display: block;
  height: 3px;
  transition: background .1s ease-in-out, color .1s ease-in-out;
  width: 24px;
}

.align-self-center {
  align-self: center !important;
}

.sidebar-toggle {
  cursor: pointer;
  display: flex;
  height: 26px;
  width: 26px;
}

.sidebar.collapsed {
  margin-left: -230px;
}

.nav-flag,
.nav-icon {
  color: #6c757d;
  display: block;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: .1rem .8rem;
  transition: background .1s ease-in-out, color .1s ease-in-out;
}

.position-relative {
  position: relative !important;
}

.nav-flag .feather,
.nav-flag svg,
.nav-icon .feather,
.nav-icon svg {
  height: 25px;
  width: 25px;
  color: #fff;
}

.nav-item .indicator {
  background: #FD346E;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, .05);
  color: #fff;
  display: block;
  font-size: .675rem;
  height: 18px;
  padding: 1px;
  position: absolute;
  right: -8px;
  text-align: center;
  top: -16px;
  transition: top .1s ease-out;
  width: 18px;
  line-height: 16px;
}

.avatar-border img {
  max-width: 40px;
  margin: 0 0 0 20px;
}

.navbar-nav>li.avatar-border>a {
  padding: 0px !important;
}

.tabs {
  padding: 0;
  list-style: none;
  position: relative;

}

.tabs .active-tab {

  border-bottom: 3px solid #6724F0;
  position: relative;
  color: black;
}

.tabs li:hover {
  border-bottom: 3px solid #6724F0;
}

/*.tabs .active-tab:after {
  width: 100%;
  height: 2px;
  position: absolute;
  content: "";
  bottom: -0.1em;
  left: 0;
  background: white;
}*/
.tabs li {
  display: inline-block;
  cursor: pointer;
  color: #3a5ea7;
  padding: 5px 10px;
}

.tabs li:first-child {
  margin-left: 10px;
}

.tabs-content {
  margin: 20px;
  padding: 0;
  list-style: none;
  border-radius: 6px;

}

.tabs-content li {
  display: none;
}

.bul-tab-seatch-head {
  display: flex;
  padding: 10px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.bul-search-header {
  margin-left: auto;
}

.tabs img {
  max-width: 23px;
}

.bul-tab-seatch-head .tabs span {
  vertical-align: top;
  line-height: 20px;
  margin-left: 7px;
  margin-right: 5px;
  font-weight: 600;
}

.bul-search-header .bul-input {
  width: 35%;
  border: 1px solid #9D9D9D;
  padding: 10px;
  height: 40px;
  border-radius: 6px;
}

.bul-search-header .btn {
  vertical-align: top;
}


.childtabs {
  overflow: hidden;
  background-color: #7D24F0;
  border-top: 3px solid #7D24F0;
  padding-left: 3px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Style the buttons inside the tab */
.childtabs button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 500;
}

/* Change background color of buttons on hover */
.childtabs button:hover {
  background-color: #fff;
  color: #333;
}

/* Create an active/current tablink class */
.childtabs button.active {
  background-color: #fff;
  color: #333;
}

/* Style the tab content */
.tabcontent {
  visibility: hidden;
  padding: 6px 12px;
  border-top: none;
  opacity: 0;
  transition: opacity 1s;
  height: 0;
}

.tabcontent.activetab {
  visibility: visible;
  opacity: 1;
  transition: 1s;
  height: auto;
}

.dt-buttons .btn {
  background: transparent;
  color: #333;
  border: 1px solid #989494;
}

.dt-buttons .btn:hover {
  background: #ddd;
}

.sidebar-item .list-unstyled {
  position: absolute;
  left: 191px;
  background: #7700ff;
  width: 175px;
  top: 0;
  /* background: linear-gradient(90deg, rgba(169, 36, 240, 1) 0%, rgba(103, 36, 240, 1) 100%); */
  color: #fff;
  z-index: 999999;
  padding-bottom: 24px;
}