
.bu-nav-menu{
    text-align: left;
}
.bul-logo-sec.collapse{
    margin-left: -15px;
    width: 43px;
    overflow: hidden;
}
.bul-nav-sidebar.collapse{
    width: 62px;
    overflow: hidden;
    white-space: nowrap;
}
.tab-wrapper .MuiTab-root{
    text-transform: none;
    font-size: 14px;
    color: rgb(103, 36, 240);
}

.date-wrapper{
    width: 150px;
}
.date-wrapper .MuiInputBase-input{
    padding: 10px;
    font-size: 14px;
}

.subTab .MuiTab-root{
    padding: 10px;
}

.card-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.card-list-wrapper > .MuiCard-root{
    min-width: 250px;
    margin-bottom: 15px;
}

.MuiTabs-vertical{
    min-width: 130px;
    max-width: 130px;
}
