
.section-wrapper{
    padding: 20px;
    margin-top: -20px;
}
.add-agency{
    margin-left: auto;
}
.rdt_TableHeadRow{
    color: white;
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
}
.menu-comp-title{
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
}
.bul-button-sec{
    padding: 25px 30px 20px 30px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    align-items: baseline;
    margin: 0 -15px;
    /* margin-bottom: 10px; */
}
.icon-wrapper{
    gap: 5px;
    display: flex;
}

.icon-wrapper svg{
    cursor: pointer;
}

.slideWrapper{
    transition: max-height 0.7s ease;
    overflow: hidden;
    background-color: #fff;
}

.slideWrapper.collapsed{
    max-height: 0;
}

.slideWrapper.expand{
    /* max-height: 500px; */
    overflow: auto;
    overflow-x: hidden;
}

.tableWrapper.expand{
    display: none;
}

.tableWrapper{
    margin: 20px 5px;
    background-color: white;
}
.tableWrapper .MuiAutocomplete-root{
    padding: 15px;
    width: 300px;
}
.tableWrapper .menu-comp-title{
    padding: 10px;
}
