
.profile-wrapper .readonly .MuiOutlinedInput-notchedOutline{
    border: none;
}
.bul-profilesec-header {
    background-color: #F1F1F1;
    padding: 15px;
    border: 1px solid #D2D2D2;
}
.bul-update-profile,.bul-profile-acc-detail {
    border: 1px solid #D2D2D2;
    padding: 30px;
    background: #fff;
    border-top: none;
    min-height: 400px;
}
.bul-update-profile p {
    font-size: 12px;
    padding: 10px 0;
}
.bul-profile-acc-detail  .col-md-4 {
    padding-bottom: 25px;
}
.bul-profilesec-header h3 {
    font-size: 18px;
    font-weight: 600;
}
.bul-profile-acc-detail button {
    margin-right: 15px;
}