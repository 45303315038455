
.compose-wrapper{
    padding: 25px;
}
.compose-field-wrapper{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.compose-field-wrapper h3{
    width: 50px;
    padding-top: 15px;
}
.compose-field-wrapper .MuiInputBase-root{
    max-height: 40px;
}
.compose-field-wrapper .MuiInputBase-root{
    width: 100%;
    max-width: unset;
}
.ql-editor{
    min-height: 100px;
    max-height: 200px;
}
.compose-wrapper > h3{
    margin: 10px 0;
}
.ql-toolbar.ql-snow{
    background-color: #b7dbfb;
}
.dropzone {
    width: 100%;
    height: 100px;
    border: 1px dashed #ccc;
    color: #aaa;
    text-align: center;
    padding-top: 40px;
}
.compose-header{
    display: flex;
    margin-bottom: 20px;
    margin-top: -10px;
}
.compose-header > div{
    flex: 1;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    padding-left: 0px;
    margin-top: 9px;
}
.compose-field-wrapper .MuiFormControl-root.MuiTextField-root{
    max-width: unset;
    width: 100%;
}
.compose-wrapper .react-multi-email{
    border-color: #ccc;
}
.compose-wrapper aside{
    margin-top: 10px
}
.compose-wrapper aside ul{
    display: flex;
    gap: 10px;
}
.compose-wrapper aside li{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    padding-top: 5px;
}
.compose-wrapper .btn{
    padding: 0 10px;
    max-height: 35px;
}