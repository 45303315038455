
/* .bul-login .MuiOutlinedInput-notchedOutline{
    border: none;
} */

.bul-login .MuiTextField-root{
    width: 100%;
    margin-bottom: 25px;
}

.bul-login .bul-lg-form-cnt input{
    border:none;
    padding: 16.5px 14px;
    margin: auto;
}
